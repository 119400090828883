<template>
  <SectionTitle
    icon="icon-about"
    h2="Adatvédelmi nyilatkozat"
    h2small=""
    createdate=""
    tag1=""
    tag1url=""
    tag2=""
    tag2url=""
    tag3=""
    tag3url=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 col-xxl-10 offset-lg-1 offset-xxl-1">
          <h3>// Adatvédeli tájékoztató</h3>
          <p class="lead">
            Az ARDA Kft. (továbbiakban: Adatkezelő) online értékesítési
            tevékenységével kapcsolatban kezeli a regisztrált felhasználók
            személyes adatait, kizárólag a megrendelések teljesítésének
            céljából, és a teljesítéssel kapcsolatos kötelezettsége (számlázás)
            teljesítése érdekében. Csak olyan személyes adatot kezel, amely az
            adatkezelés céljának megvalósulásához elengedhetetlen, és a cél
            elérésére alkalmas. A személyes adatokat csak a cél megvalósulásához
            szükséges mértékben és ideig kezeli.
          </p>
          <p class="lead">
            Az Adatkezelő minden elvárható módon védi a regisztrált
            felhasználóknak az oldal által bekért, ott kezelt személyes adatait.
            Az adatkezelő címe: ARDA Kft. 2440 Százhalombatta, Halom utca 17. Az
            adatkezelő elérhetősége: arda@arda.hu Adatvédelmi nyilvántartási
            számaink: NAIH-84125/2015, NAIH-84126/2015.
          </p>
          <p class="lead">
            Az adatkezelés jogalapja: 2011. évi CXII. törvény az információs
            önrendelkezési jogról és az információszabadságról 5. § (1) bekezdés
            a) Az érintett hozzájárulása. Hozzájárulás az adatkezeléshez: A
            felhasználó regisztrációnál kifejezetten hozzájárulását adja az
            általa önkéntesen megadott személyes adatainak kezeléséhez. Az
            érintettek köre: regisztrált felhasználók
          </p>
          <p class="lead">
            A kezelt adatok köre: a regisztrált felhasználók által a
            regisztráció során megadott a megrendelés teljesítéséhez szükséges
            adatok: Név, Elérhetőség (telefon, email), számlázási cím Az
            adatgyűjtés célja: a megrendelések teljesítése és számlázás,
            információnyújtás Az adatok megismerésére jogosult lehetséges
            adatkezelők személye: ARDA Kft. ügyvezetője: Wilhelm Zsolt. A
            vásárló kérelmezheti az adatkezelőnél – tájékoztatását személyes
            adatai kezeléséről, – személyes adatainak helyesbítését, – személyes
            adatainak törlését vagy zárolását.
          </p>
          <p class="lead">
            Ha korábban megrendelést küldött el, akkor a megrendeléshez az
            elküldés pillanatában érvényes adatok lettek csatolva. A
            megrendeléshez kapcsolt személyes adatairól tájékoztatást, ezek
            módosítását vagy törlését az adatkezelőnél személyesen, telefonon
            vagy e-mailben kezdeményezheti. Az adatkezelő a kérelem
            benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 10
            napon belül, közérthető formában, a regisztrált felhasználó erre
            irányuló kérelmére írásban megadja a tájékoztatást. Ha a regisztrált
            felhasználó kérelmét jogosnak találja, akkor haladéktalanul
            intézkedik a személyes adatainak helyesbítése vagy törlése
            érdekében. Ha a regisztrált felhasználó nem elégedett az adatkezelő
            válaszával, akkor személyes adatai védelméhez való jogát polgári
            bíróság előtt érvényesítheti, továbbá a Nemzeti Fejlesztési
            Minisztériumhoz, mint Hatósághoz fordulhat. A 2011. évi CXII.
            törvény az információs önrendelkezési jogról és az
            információszabadságról itt olvasható: Nemzeti Jogszabálytár
            (www.njt.hu)
          </p>
          <h3>// Süti (cookie) kezelés</h3>
          <p class="lead">
            A jelen Süti (angolul Cookie) Szabályzat (a továbbiakban: cookie) az
            arda.HU, avagy az ARDA Kft. , mint szolgáltató (“Szolgáltató”) által
            üzemeltetett honlap használatára vonatkozó cookie feltételeket
            tartalmazzák. A weboldal kialakításánál figyeltünk a cookie-k
            további felhasználására vonatkozó előírásokra. Ezen használatra
            vonatkozóan az alábbi előírásokat vettük figyelembe és ezeknek
            megfelelően használjuk: 2003. évi C. törvény az elektronikus
            hírközlésről 2001. évi CVIII. törvény az elektronikus kereskedelmi
            szolgáltatások, valamint az információs társadalommal összefüggő
            szolgáltatások egyes kérdéseiről 2011. évi CXII. törvény az
            információs önrendelkezési jogról és az információszabadságról
            2002/58/EK irányelv (2002. július 12.) az elektronikus hírközlési
            ágazatban a személyes adatok kezeléséről, feldolgozásáról és a
            magánélet védelméről (“Elektronikus hírközlési adatvédelmi
            irányelv”) Kérjük, hogy a dokumentumot figyelmesen olvassa el és
            csak akkor vegye igénybe szolgáltatásomat, amennyiben minden
            pontjával egyetért és azokat önre (a továbbiakban: Felhasználó)
            nézve kötelező érvényűnek elfogadja. Felhívjuk figyelmét, hogy jelen
            szabályzat csak az adott weboldalon történő cookie-kezelésre
            vonatkozik. Amennyiben ön a honlapon található, külső irányba
            történő hivatkozásra kattint, akkor keresse meg és olvassa át azon
            weboldal saját szabályzatát is.
          </p>
          <p class="lead">
            A cookie vagy süti jelentése: file-ok vagy információ darabkák,
            amelyeket internetes böngészője ment le honlapunkról és tárolja
            azokat az Ön gépén. Ezeknek a cookieknak a segítségével ismeri fel a
            honlapunk anyagait tároló szerver számítógép a honlapunkra történő
            visszalátogatásakor, hogy Ön már járt a honlapjainkon. A legtöbb
            internetes böngésző alapbeállításából kifolyólag elfogad cookie-kat.
            Ha úgy gondolja, átállíthatja böngészőjét, hogy a cookie-kat
            visszautasítsa vagy figyelmeztesse Önt arra, hogy cookie-kat küldtek
            az Ön gépére. Weboldalunk használ ilyen cookie fájlokat, egyes
            funkciók biztosítása érdekében vagy csupán kényelmi okok miatt. Az
            általunk használt cookie-k nem terhelik, lassítják és nem okoznak
            kárt az Ön számítógépében. Az oldal Harmadik féltől származó
            cookie-k, sütik használata is történik a weboldalon. A cookie-k,
            sütit törölhetőek és tilthatóak a böngészőkből. Továbbá le is
            tilthatóak a sütik, cookie-k. Ezen beállításairól a böngésző
            hivatalos weboldalán talál információt.
          </p>
          <p class="lead">
            Többféle célra is használjuk ezeket a technológiákat, például a
            Felhasználó számára legrelevánsabb tartalom vagy hirdetés
            megjelenítésére; termékeink és szolgáltatásaink továbbfejlesztésére;
            valamint szolgáltatásaink biztonságának megőrzésére. Az általunk
            használt cookie-k, képpontok és egyéb hasonló technológiák pontos
            neve a szolgáltatások fejlesztésével és frissítésével időről-időre
            változhat. Először a kliens gép küld egy kérést a kiszolgáló
            irányába. Ekkor a kiszolgáló létrehoz egy egyedi azonosítót és ezt
            eltárolja a saját adatbázisában, majd az így létrehozott cookie-t az
            összes információval visszaküldi a kliensnek. Az így visszakapott
            információs cookie eltárolódik a kliens gépen. Amikor a kliens gép
            újra kapcsolatba lép a kiszolgálóval már hozzácsatolja az előzőleg
            már létrehozott és eltárolt cookie-t. A kiszolgáló összehasonlítja a
            kapott és az általa tárolt cookie tartalmát. Ez által könnyedén
            azonosítja pl. a regisztrált felhasználót.
          </p>
          <p class="lead">
            Többféle cookie van használatban, de minden weboldal másféle
            típusúakat használ. Honlapunk általában csak az alábbiakat
            használja, de a fejlesztések alkalmával előfordulhat, hogy az
            eddigieken felül újabb fajtákatt is alkalmazunk.
            <br />
            Munkameneti/Átmeneti cookie: ezek a cookie-k csak átmenetileg
            kerülnek az ideiglenes memóriába, ameddig a felhasználó az oldalon
            navigál. Amikor a felhasználó bezárja a böngészőt a cookie törlődik.
            Ezek a cookie-k nem tartalmaznak személyes adatokat és nem
            alkalmasak arra, hogy a látogatót beazonosítsa.
            <br />
            Tárolt/Állandó cookie: ezek azok a cookie-k, amelyek minden
            alkalommal használatba kerülnek, amikor a felhasználó az oldalra
            látogat. A cookie-k fajtája alapján az alábbiak szerint
            használhatjuk ezeket:
            <br />
            Analitika/Elemzés: ez mutatja meg, hogy a weboldalon belül merre
            járt, milyen termékeket nézett meg, mit csinált. A cookie
            élettartamától függően marad a kliens gépen. Olyan funkciók
            használhatják, mint például a Google Analytics vagy a Youtube. Ezek
            a cookie-k nem tartalmaznak személyes adatokat és nem alkalmasak
            arra, hogy a látogatót beazonosítsa.
            <br />
            Közösségi hálózatok: lehetővé teszi, hogy könnyen hozzáférjen a
            közösségi média hálózatokhoz, megoszthassa véleményét, termékeink
            információit másokkal. olyan harmadik fél által nyújtott funkciók
            használhatják, mint például a Facebook, Twitter, Google+, Pinterest
            vagy a Youtube. Ezek a cookie-k tartalmazhatnak személyes adatokat
            és alkalmasak a látogató beazonosítására.
            <br />
            Média: ezek segítségével képes például videókat megtekinteni az
            oldalon. Olyan harmadik fél által nyújtott funkciók használják, mint
            például a Youtube. Ezek a cookie-k nem tartalmaznak személyes
            adatokat és nem alkalmasak arra, hogy a látogatót beazonosítsa.
            <br />
            Funkcionális: ez mutatja meg, hogy a felhasználó járt e már az
            oldalon és azt milyen eszközzel tette. Megjegyzi a felhasználó
            nevet, jelszót, választott nyelvet, tartózkodási információkat. Ezek
            a cookie-k tartalmazhatnak személyes adatokat és alkalmasak a
            látogató beazonosítására. <br />
            Reklám: ezek segítségével az érdeklődésének megfelelő információkat,
            hírleveleket tudok küldeni a felhasználónak. Ezek a cookie-k
            tartalmazhatnak személyes adatokat és alkalmasak a látogató
            beazonosítására.
          </p>
          <p class="lead">
            Különféle módokon, de a kliensnek lehetősége van többféle módon
            beállítani a cookie-k kezelésére vonatkozóan böngészőjét.
            Általánosságban elmondható, hogy az alábbiak szerint háromféle módon
            állíthatóak be a böngészők: Minden cookie elfogadása Minden cookie
            elutasítása Minden egyes cookie használatról értesítés kérése A
            cookie-k beállításaival kapcsolatban érdemes körülnézni böngészője
            „Opciók” vagy „Beállítások” menüjében vagy igéybe venni keresője
            „Segítség” menüjét.
          </p>
          <p class="lead">
            Fontos megjegyezni, hogy a weboldal cookie kezeléssel készült.
            Amennyiben a kliens részlegesen vagy teljes egészében letiltja ezek
            használatát, megakadályozhatja a weboldal működését. Amennyiben ez
            így történik, lehetnek olyan funkciók és szolgáltatások, amelyeket
            nem fog tudni igénybe venni teljes, vagy részleges mértékben. Akkor
            is használunk cookie-kat, ha nincs a Felhasználónak regisztrált
            fiókja, illetve ha kijelentkezett a fiókból. Ha például a
            Felhasználó kijelentkezett a fiókjából, a következők megkönnyítésére
            használunk cookie-kat:
            <br />
            levélszemetet (spam) küldők fiókjainak azonosítása és letiltása a
            fiók helyreállítása, ha esetleg elvesztene a hozzáférés további
            biztonsági funkciók biztosítása, mint például a bejelentkezési
            értesítések és a bejelentkezés-jóváhagyások kiskorú személyek hamis
            születési dátummal való regisztrálásának megakadályozása az oldalon
            és máshol megjelenített hirdetések megjelenítése, kiválasztása,
            értékelése, mérése és értelmezése (a társvállalkozásaink vagy
            partnereink által vagy nevében megjelenített hirdetéseket is
            beleértve) elemzési információk összeállítása azokról a
            személyekről, akik szolgáltatásainkkal, valamint hirdetőink és
            partnereink weboldalaival érintkezésbe lépnek.
          </p>
          <p class="lead">
            A szolgáltatások és használóinak rosszindulatú tevékenységekkel
            szembeni védelem érdekében akkor is elhelyezünk cookie-kat, ha nincs
            a Felhasználónak fiókja regisztrálva, de ellátogatott a
            webhelyünkre. Például ezek a cookie-k segítenek észlelnünk és
            megakadályoznunk a szolgáltatás megszakítását célzó támadásokat,
            valamint a hamis fiókok tömeges létrehozását. Ha a böngészőben, vagy
            a készüléken vannak cookie-k, le tudjuk olvasni az adott cookie-t,
            amikor közösségi modullal rendelkező weboldalra látogat. A Közösségi
            honlapok (Facebook, Twitter, LinkedIn, GooglePlus) által létrehozott
            cookie-kért azon oldalakat üzemeltető felel, melyről az adott
            közösségi oldal honlapján tud informálódni.
          </p>
          <p class="lead"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
};
</script>
